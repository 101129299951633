<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="货物">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                @keyup.enter.native="$refs.table.refresh(true)"
                v-model="queryParam.bonded_goods_id"
                placeholder="请输入货物"
                :default-active-first-option="false"
                @search="onSearchGood"
                @change="handleGoodsChange"
              >
                <a-select-option v-for="d in dataSource_goods" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="仓库">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                show-search
                allow-clear
                @keyup.enter.native="$refs.table.refresh(true)"
                v-model="queryParam.bonded_warehouse_id"
                placeholder="请输入仓库名称"
                @search="onSearchWarehouse"
                @change="onChangeWarehouse"
              >
                <a-select-option v-for="item in dataSource_warehouse" :tt="item.name" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="仓位">
              <a-select
                option-label-prop="tt"
                placeholder="请选择"
                @keyup.enter.native="$refs.table.refresh(true)"
                v-model="queryParam.bonded_cargo_space_id"
                @change="onChangeSpace"
              >
                <a-select-option v-for="item in dataSource_cargo_space" :tt="item.name" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="商品批次">
              <a-select
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                @keyup.enter.native="$refs.table.refresh(true)"
                v-model="queryParam.bonded_commodity_id"
                placeholder="请输入商品批次"
                @search="onSearchCommodity"
                @change="onChangeCommodity"
                option-label-prop="tt"
              >
                <a-select-option v-for="item in dataSourceCommodity" :tt="item.batch" :key="item.id">
                  {{ item.batch }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
      <div class="table-operator">
        <a-button type="primary" icon="export" @click="handleExcel(0)">导出全部</a-button>
        <a-button type="primary" icon="export" @click="handleExcel(1)">导出非0</a-button>
      </div>
    </div>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 120px">
        {{ text }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleOut(record)">出库</a>
        </template>
      </span>
    </s-table>
    <a-modal :visible="OutVisible" :footer="null" @cancel="handleCancel" v-if="OutVisible" style="width: 300px">
      <a-form-model layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="出库数量">
              <a-input-number
                v-model="currentRecord.out_count"
                placeholder="出库数量"
                style="width: 180px;"
                :max="currentRecord.count - currentRecord.freeze_count"
              />
              最大出库数量：{{ currentRecord.count - currentRecord.freeze_count }}
            </a-form-item>
            <a-form-model-item :wrapper-col="{ span: 23, offset: 1 }" style="width: 180px">
              <a-button type="primary" @click="onSubmit">
                确定
              </a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import { bonded_warehouse_bonded_commodity_list, bonded_warehouse_list } from '@/api/bonded_warehouse'
import { bonded_goods_list } from '@/api/bonded_goods'
import { bonded_cargo_space_list } from '@/api/bonded_cargo_space'
import {
  bonded_commodity_list,
  bonded_commodity_out_warehouse_create,
  bonded_commodity_warehouse_excel
} from '@/api/bonded_commodity'

export default {
  name: 'TableList',
  components: {
    STable,
    AAutoComplete
  },
  data () {
    return {
      currentRecord: {},
      OutVisible: false,
      dataSource_warehouse: [],
      dataSource_goods: [],
      tip_goods: '',
      corListGood: [],
      dataSource_cargo_space: [],
      tip_cargo_space: '',
      corListSpace: [],
      dataSourceCommodity: [],
      corListCommodity: [],
      tip_commodity: '',
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '货物',
          dataIndex: 'bonded_goods'
        },
        {
          title: '保税商品',
          dataIndex: 'bonded_commodity'
        },
        {
          title: '仓库',
          dataIndex: 'bonded_warehouse'
        },
        {
          title: '货位',
          dataIndex: 'bonded_cargo_space'
        },
        {
          title: '数量',
          dataIndex: 'count',
          align: 'center',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '冻结数量',
          dataIndex: 'freeze_count',
          align: 'center',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '创建时间',
          customRender: (text) => text || '-',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return bonded_warehouse_bonded_commodity_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            return data
          })
      }
    }
  },
  created () {
  },
  methods: {
    onSubmit () {
      let params = Object.assign({}, this.currentRecord)
      params.count = params.out_count
      if (!params.count) {
        return
      }
      bonded_commodity_out_warehouse_create(params)
        .then(({ data }) => {
          console.log(data)
          this.OutVisible = false
        })
        .catch((err) => {
          console.log(err)
          this.OutVisible = false
        })
    },
    handleOut (record) {
      this.OutVisible = true
      console.log(record)
      this.currentRecord = Object.assign({}, record)
    },
    handleCancel (file) {
      this.OutVisible = false
    },
    onSearchGood (searchText) {
      bonded_goods_list({ name: searchText }).then((res) => {
        let result = res.data.entries || []
        if (result.length === 0) {
          this.tip_goods = '未找到您输入的货物'
        } else {
          this.tip_goods = ''
        }
        this.dataSource_goods = !searchText ? [] : result
      })
    },
    handleGoodsChange (value) {
      this.queryParam.bonded_goods_id = value
      this.exhibition_goods_value = value
    },
    //  warehouse
    onSearchWarehouse (searchText) {
      bonded_warehouse_list({ name: searchText }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_warehouse = !searchText ? [] : result
      })
    },
    onChangeWarehouse (value) {
      this.queryParam.bonded_warehouse_id = value
      this.queryParam.bonded_cargo_space_id = undefined
      this.onSearchSpace()
    },
    //  space
    onSearchSpace () {
      bonded_cargo_space_list({ bonded_warehouse_id: this.queryParam.bonded_warehouse_id }).then((res) => {
        this.dataSource_cargo_space = res.data.entries || []
      })
    },
    onChangeSpace (value) {
      this.queryParam.bonded_cargo_space_id = value
      this.$forceUpdate()
    },
    //  commodity
    onSearchCommodity (searchText) {
      bonded_commodity_list({ batch: searchText }).then((res) => {
        const result = res.data.entries || []
        if (result.length === 0) {
          this.tip_commodity = '未找到您输入的保税商品'
        } else {
          this.tip_commodity = ''
        }
        this.dataSourceCommodity = !searchText ? [] : result
      })
    },
    onChangeCommodity (value) {
      this.queryParam.bonded_commodity_id = value
    },
    // 导出
    handleExcel (type) {
      const exclude_zero = type === 1
      bonded_commodity_warehouse_excel({ exclude_zero: exclude_zero }).then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', '保税商品库存.xls')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }
  }
}
</script>
<style lang="less">
.goodslist .ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 0px !important;
}

.goodslist .ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 0px !important;
  margin-left: 0px !important;
  line-height: 30px !important;
}
</style>
